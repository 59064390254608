//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapGetters } from 'vuex';
import {
  IdCellRenderer,
  PhotoCellRenderer,
  TitleCellRenderer,
  TypeCellRenderer,
  QrCodeCellRenderer,
  StatusCellRenderer,
  CreatedCellRenderer,
  ActionsCellRenderer,
  CategoryRenderer,
  SecurityCellRenderer,
  TagCellRenderer,
  DictionaryCellRenderer
} from './index.ts';
export default {
  components: {
    AdvancedInfoSetting: () => import('../modal/AdvancedInfoSetting.vue'),
    PublicCodesSetting: () => import('../modal/PublicCodesSetting.vue'),
    CategorySetting: () => import('../modal/CategorySetting.vue'),
    ChoosePageModal: () => import('../modal/ChoosePageModal.vue'),
    IdCellRenderer,
    PhotoCellRenderer,
    TitleCellRenderer,
    TypeCellRenderer,
    QrCodeCellRenderer,
    StatusCellRenderer,
    CreatedCellRenderer,
    ActionsCellRenderer,
    CategoryRenderer,
    SecurityCellRenderer,
    TagCellRenderer,
    DictionaryCellRenderer
  },

  props: {
    rowData: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      context: { componentParent: this },
      publicCodeSettingModalVisible: false,
      categorySettingModalVisible: false,
      advancedInfoSettingModalVisible: false,
      choosePageModalVisible: false,
      pageChoosingActionType: '',
      selectedCodes: [],
      dragEnterIndex: 0,
      loading: false,
      gridApi: null
    };
  },

  computed: {
    ...mapGetters('page', ['pages']),
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById', 'projectsAndPages']),
    columnDefs() {
      return [
        {
          headerName: 'code.grid.ID',
          field: 'id',
          rowDrag: true,
          width: 120,
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'IdCellRenderer'
        },
        {
          headerName: 'code.grid.Photo',
          field: 'photo',
          width: 180,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'PhotoCellRenderer'
        },
        {
          headerName: 'code.grid.Title',
          field: 'title',
          width: 200,
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'TitleCellRenderer'
        },
        {
          headerName: 'code.grid.Category',
          field: 'category_id',
          lockPosition: true,
          sortable: true,
          unSortIcon: true,
          cellClass: 'locked-col',
          width: 150,
          cellRenderer: 'CategoryRenderer'
        },
        {
          headerName: 'code.grid.Type',
          field: 'code_type',
          width: 150,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'TypeCellRenderer'
        },
        {
          headerName: 'Voice code',
          field: 'preview_qrcode',
          width: 150,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'QrCodeCellRenderer'
        },
        {
          headerName: 'code.grid.Status',
          field: 'status',
          width: 180,
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'StatusCellRenderer'
        },
        {
          headerName: 'code.grid.Dictionary',
          field: 'dictionary',
          width: 120,
          lockPosition: true,
          cellRenderer: 'DictionaryCellRenderer'
        },
        {
          headerName: 'Tags',
          field: 'tags',
          width: 150,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'TagCellRenderer'
        },
        {
          headerName: 'code.grid.Created',
          field: 'created',
          width: 120,
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'CreatedCellRenderer'
        },
        {
          headerName: 'code.grid.PublishSetting',
          field: 'ispublic',
          width: 120,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'SecurityCellRenderer'
        },
        {
          headerName: 'project.grid.Actions',
          width: 120,
          field: 'actions',
          lockPosition: true,
          pinned: 'right',
          cellClass: 'locked-col',
          cellRenderer: 'ActionsCellRenderer'
        }
      ];
    },
    hasSpotOrNaviCode() {
      return this.selectedCodes.some(item => ['navi_code', 'spot_code'].includes(item.code_type));
    },
    pageId() {
      return this.$route.params.pageId;
    },
    isSelectedCode() {
      return this.selectedCodes.length > 0;
    }
  },
  created() {
    this.getProjectsAndPagesByCompany(this.userInfo.company_id);
  },
  methods: {
    ...mapActions('code', ['updateCode']),
    ...mapMutations('code', ['UPDATE_ORDER']),
    ...mapActions('project', ['getProjectsAndPagesByCompany']),

    handleGridReady(params) {
      this.gridApi = params.api;
    },
    rowDragText(params) {
      const { id, title } = params.rowNode.data;
      return `${id} - ${title}`;
    },
    clearAllSelected() {
      this.$refs.listRef?.deselectAll();
    },
    openPublicCodesSetting() {
      this.publicCodeSettingModalVisible = true;
    },
    openCategorySetting() {
      this.categorySettingModalVisible = true;
    },
    openAdvancedInfoSetting() {
      this.advancedInfoSettingModalVisible = true;
    },
    handleCopyCodesOpening() {
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'copy';
    },
    handleMoveCodesOpening() {
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'move';
    },
    handlePageChanging({ page, pageSize }) {
      this.$emit('changePaging', { pageCurrent: page, pageSize });
    },
    updatePublicSetting(payload) {
      new Promise((resolve, reject) => {
        this.loading = true;
        this.$emit('updateCodes', {
          selectedCodes: this.selectedCodes,
          payload,
          resolve,
          reject
        });
      })
        .then(() => {
          this.publicCodeSettingModalVisible = false;
          this.clearAllSelected();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCategorySetting(payload) {
      new Promise((resolve, reject) => {
        this.loading = true;
        this.$emit('updateCodes', {
          selectedCodes: this.selectedCodes,
          payload,
          resolve,
          reject
        });
      })
        .then(() => {
          this.categorySettingModalVisible = false;
          this.clearAllSelected();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateAdvanceInfoSetting(payload) {
      new Promise((resolve, reject) => {
        this.loading = true;
        this.$emit('updateCodes', {
          selectedCodes: this.selectedCodes,
          payload,
          resolve,
          reject
        });
      })
        .then(() => {
          this.advancedInfoSettingModalVisible = false;
          this.clearAllSelected();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCodeSelecting(e) {
      const nodes = e.api.getSelectedNodes();
      this.selectedCodes = nodes.map(node => node.data);
    },
    rowDragEnter({ overIndex }) {
      this.dragEnterIndex = overIndex;
      this.gridApi.previousRowDatas = this.gridApi.rowModel.rootNode.allLeafChildren.map(
        item => item.data
      );
    },
    async rowDragEnd({ overIndex, node }) {
      try {
        const previousRowDatas = this.gridApi.previousRowDatas;
        const currentRowDatas = this.gridApi.rowModel.rootNode.allLeafChildren.map(
          item => item.data
        );
        // This case: this.rowData is Not re-rendered before we update the order , so we need to update the order manually temporarily
        let orderBy = 0;
        // Return if the drag and drop position is the same
        if (overIndex === this.dragEnterIndex) return;
        // Get target before the row data ORDER is changed by drag and drop
        const targetCodeOrderBy = previousRowDatas[overIndex].order_by;
        const firstCodeOrderBy = currentRowDatas[overIndex + 1]?.order_by || 0;
        const secondCodeOrderBy = currentRowDatas[overIndex - 1]?.order_by || 0;
        if (overIndex === 0) {
          // Drag and drop to the first position
          orderBy = targetCodeOrderBy + 0.5;
        } else if (overIndex === this.rowData.length - 1) {
          // Drag and drop to the last position
          orderBy = targetCodeOrderBy - 0.5;
        } else {
          // Drag and drop to the middle position
          orderBy = (firstCodeOrderBy + secondCodeOrderBy) / 2;
        }
        // Update the order by
        const data = await this.$s.api.page.editCodeByIdWithRestriction(
          this.pageId,
          node?.data?.id,
          {
            order_by: orderBy
          }
        );
        this.UPDATE_ORDER({ id: data.id, order_by: data.order_by });
        const flashRow = this.gridApi.getDisplayedRowAtIndex(overIndex);
        this.gridApi.flashCells({ rowNodes: [flashRow] });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: this.$t('Error') });
      }
    },
    handleDeleteCodes(codes) {
      this.deleteCodes(codes.map(item => item.id));
    },
    handleDeleteAllCodes() {
      this.deleteCodes('all');
    },
    deleteCodes(codeIds) {
      new Promise((resolve, reject) => {
        this.loading = true;
        this.$emit('deleteCodes', {
          codeIds: codeIds,
          resolve,
          reject
        });
      })
        .then(() => {
          this.clearAllSelected();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCopyCodes({ selectedPageId, codeIds, resolve }) {
      new Promise((resolve, reject) => {
        this.$emit('copyCodes', {
          selectedPageId,
          codeIds,
          resolve,
          reject
        });
      }).finally(() => {
        resolve(1);
        this.choosePageModalVisible = false;
      });
    },
    handleMoveCodes({ selectedPageId, codeIds, resolve }) {
      new Promise((resolve, reject) => {
        this.$emit('moveCodes', {
          selectedPageId,
          codeIds,
          resolve,
          reject
        });
      }).finally(() => {
        resolve(1);
        this.choosePageModalVisible = false;
      });
    },
    duplicateCode(codeId) {
      new Promise((resolve, reject) => {
        this.$emit('duplicateCode', { codeId, resolve, reject });
      });
    }
  }
};

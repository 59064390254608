//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapGetters('page', ['pageById']),
    ...mapGetters('project', ['projectById']),
    projectId() {
      return this.$route.params.projectId;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    currentPage() {
      return this.pageById(this.pageId) || {};
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    projectDictionary() {
      return this.currentProject?.dictionary || []
    },
    pageDictionary() {
      return this.currentPage?.dictionary || []
    },
    codeDictionary() {
      return this.params.data?.dictionary || []
    },
    usedDictionary() {
      return (this.codeDictionary.length > 0
        ? this.codeDictionary
        : this.pageDictionary?.length > 0
          ? this.pageDictionary
          : this.projectDictionary) || []
    },
    isUsedProjectDictionaryAsDefault() {
      const projectDicString = JSON.stringify([...(this.projectDictionary || [])].sort())
      const usedDicString = JSON.stringify([...(this.usedDictionary || [])].sort())
      return this.projectDictionary?.length > 0 && projectDicString == usedDicString
    },
    isUsedPageDictionaryAsDefault() {
      const pageDicString = JSON.stringify([...(this.pageDictionary || [])].sort())
      const usedDicString = JSON.stringify([...(this.usedDictionary || [])].sort())
      return this.pageDictionary?.length > 0 && pageDicString == usedDicString
    }
  },
  methods: {
    handleOpenDictionary(dicName) {
      this.$router.push(`/settings/speech-setting?dicName=${dicName}`);
    }
  }
});
